<template>
  <div class="scheme-contain">
    <div class="affixion-contain">
      <Form :label-width="140">
        <div class="services-text border-bottom">
          <Row>
            <Col span="12" offset="7">
              <Form-item label="领货时间：">
                <span class="breakAll">{{detail_data.create_time}}</span>
              </Form-item>
            </Col>
          </Row>
        </div>

        <div class="services-text border-bottom">
          <Row>
            <Col span="12" offset="7">
              <Form-item label="产品名称：">
                <span>{{detail_data.service_type}}</span>
              </Form-item>
            </Col>
          </Row>
        </div>

        <div class="services-text border-bottom">
          <Row>
            <Col span="12" offset="7">
              <Form-item label="领货数量：">
                <span>{{detail_data.number}}</span>
              </Form-item>
            </Col>
          </Row>
        </div>

        <div class="symbol-box tac mt30">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <!-- <Button type="success" size="large" @click="handleSave">保存</Button> -->
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import linghuoService from '@/services/linghuoService';
export default {
	data() {
		return {
			detail_data: {
				service_type: '',
				create_time: '',
				number: '',
			},
		};
	},
	created() {
		this.getServicesDetail();
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		getServicesDetail() {
			linghuoService
				.detailServices({ services_id: this.$route.params.services_id })
				.then((data) => {
					this.detail_data = data;
				});
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
.border-bottom {
  border-bottom: 1px solid #eee;
}
.services-text {
  margin: 0 18%;
}
.services-text .ivu-form-item {
  margin: 15px 0;
}
.text-box {
  border: 1px dashed #ccc;
  padding: 25px;
}
.ivu-form-label-left .ivu-form-item-label {
  color: #424e67;
}
.mt30 {margin-top: 30px;}
</style>
